.s-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    p {
        font-size: 18px;
        font-family: $font-family-sub;
        color: $black;
    }

    h1 {
        text-transform: uppercase;
        font-family: $font-playfair--regular;
        color: $black;
        letter-spacing: 1.6px;
        font-size: 40px;
        margin: 0;
        text-transform: uppercase;
    }

    h3 {
        font-family: $font-family-sub;
        font-size: 28px;
    }

    h4 {
        font-size: 20px;
    }
}

.breadcrumb-section {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
}

.breadcrumb {
    display: flex;
}

.breadcrumb__item {
    a {
        font-size: 16px;
        text-transform: lowercase;
        font-family: $font-family-body;
        color: $darkgrey;
        padding: 0 10px;
        transition: color 0.3s ease, background 0.3s ease;

        &:hover {
            color: $vtpink;
            text-decoration: none;
        }
    }

    &--active {
        padding: 0 10px;

        a {
            font-family: $font-family-body;
            text-transform: lowercase;
            font-size: 16px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.related-posts-box {
    h3 {
        font-family: $font-family-sub;
    }
}

.related-link {
    color: $black;
    text-decoration: none;
    font-size: 18px;
    font-weight: 100;

    &:hover {
        color: $black;

        .related-overlay {
            opacity: 1;
        }
    }
}

.related-posts {
    display: grid;
    grid-template-columns: 1fr;

    h3 {
        font-size: 40px;
        color: $darkgrey;
        text-align: center;
        line-height: 40px;
    }

    @media only screen and (min-width: 600px) and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        padding: 20px 0;
    }

    @media only screen and (min-width: 900px) and (max-width: 1201px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
        padding: 20px 0;
    }

    @include widescreen-up {
        grid-template-columns: repeat(4, 1fr);
        margin: 30px 0;
        grid-gap: 18px;
    }
}

.related-posts-box {
    position: relative;
    height: 352px;

    @include desktop-up {
        height: 285px;
        width: 285px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--first {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $vtlightgrey;
        padding: 0;
        position: relative;
        height: auto;

        h3 {
            margin: 0;
            padding: 20px 0;
        }

        @include desktop-up {
            margin-right: 10px;
            max-height: unset;
        }

        &:before {
            @include desktop-up {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border: 11px solid transparent;
                border-right: none;
                border-left-color: $vtlightgrey;
                position: absolute;
                right: -11px;
                top: 50%;
                margin-top: -11px;
                transition: all 0.3s ease;
            }
        }
    }
}

.related-post-bar {
    color: $darkgrey;
    font-size: 20px;
    height: 76px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $yellowgrey;
    padding: 15px 20px;
    overflow: hidden;
    z-index: 5;
}

.related-overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
}

.buttonContainer {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.buttonreadmore {
    font-size: 14px;
    padding: 10px 15px;
    background: $yellowgrey;
    text-transform: uppercase;
    transition: ease-in-out .3s;

    &:hover {
        background: $vtlightgrey;
    }

    a {
        color: $darkgrey;
        font-size: 12px;

        @include desktop-up {
            font-size: 14px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &--next {
        &:after {
            font-family: 'Font Awesome\ 5 Free';
            content: '\f0da';
            margin-left: 10px;
            font-weight: 900;
        }
    }

    &--prev {
        &:before {
            font-family: 'Font Awesome\ 5 Free';
            content: '\f0d9';
            margin-right: 10px;
            font-weight: 900;
        }
    }
}

.article-meta-item {
    &:hover {
        .sm-social-share-buttons {
            display: block;
            margin-left: 20px;
        }
    }
}

.article-footer__row {
    font-size: 18px;
    color: $vtgrey;
    line-height: 28px;
    border-bottom: 1px solid $vtgrey2;
    display: block;
    position: relative;
}

.share-button {
    position: relative;
}

.share-item {
    line-height: 30px;

    a {
        color: $darkgrey;

        &:hover {
            color: $vtpink;
            text-decoration: none;
        }
    }

    &:hover {
        > :before {
            color: $vtpink;
        }
    }
}

.share-link {
    display: block;
    padding-left: 30px;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.comment-button-text {
    cursor: pointer;
    margin-left: 20px;

    a {
        color: $darkgrey;


        &:hover {
            color: $vtpink;
            text-decoration: none;
        }
    }

    &:before {
        @include fontAwesome;
        content: '\f075';
        margin-right: 10px;
        color: $vtlightergrey;
    }

    &:hover {
        color: $vtpink;

        &::before {
            color: $vtpink;
        }
    }
}

.share-button-text {
    cursor: pointer;

    &:before {
        @include fontAwesome;
        content: '\f1e0';
        margin-right: 30px;
        color: $vtlightergrey;
    }

    i {
        margin-right: 10px;
    }

    &:hover {
        color: $vtpink;

        &::before {
            color: $vtpink;
        }
    }
}

.sm-social-share-buttons {
    display: none;
    position: absolute;
    background: $yellowgrey;
    padding: 10px 20px 10px 10px;
    top: 20px;
    left: 10px;
    list-style-type: none;
    z-index: 10;
    min-width: 133px;

    i {
        margin-right: 5px;
    }

    a {
        color: $darkgrey;

        &:hover {
            color: $vtpink;
            text-decoration: none;
        }
    }
}

.sm-social-share-buttons {

    .fa-facebook:before,
    .fa-twitter:before,
    .fa-google-plus:before,
    .fa-pinterest:before {
        font-family: 'Font Awesome 5 Brands';
    }
}

.article-meta-list {
    display: flex;
    list-style: none;
    padding: 0;
    align-items: center;
}

.article-meta-reply {
    margin-left: 12px;
}

.comment-respond {
    padding: 20px 20px 40px 20px;
    background: $yellowgrey;
    margin: 20px 0 40px;
    position: relative;

    textarea {
        margin-top: 12px;
    }
}

// form
textarea {
    border: solid 1px transparent;
    resize: none;
}

textarea:focus {
    border: 1px solid $vtlightgrey;
    background: $white;
}

.comment-form {
    display: flex;
    flex-direction: column;
}

.comment-form-url {
    display: none;
}

input#author,
input#email {
    border: 1px solid transparent;
}

input#email:focus,
input#author:focus {
    background: $white;
    border: 1px solid $vtlightgrey;
}

.form-submit {
    display: flex;
    order: 3;
    margin: 0;
    justify-content: flex-end;

    .submit {
        position: relative;
        text-transform: uppercase;
        padding: 0 30px;
        width: auto;
        font-weight: normal;
        background: $white;
        line-height: 42px;
        transition: color 0.3s ease, background 0.3s ease;
        margin-top: 20px;

        &:hover {
            background: $darkgrey;
            color: $white;

            &::after {
                color: $white;
            }
        }

        &:after {
            @include fontAwesome;
            content: '\f0da';
            color: $black;
            font-size: 18px;
            margin-left: 10px;
            margin-right: 0;
        }
    }
}


.vtOption {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $darkgrey;
    margin: 0 0 15px 0;
    order: 2;

    input[type="checkbox"] {
        opacity: 0;
    }

    span {
        margin-left: 10px;
    }

    @include desktop-up {
        display: inline-flex;
        align-items: center;
    }
}

.vtlink {
    text-decoration: underline;
    font-weight: bold;
    color: $darkgrey;

    &:hover {
        text-decoration: none;
        color: $darkgrey;
    }
}

.option-box {
    order: 2;
    margin-right: 0;
    display: none;
}

.field__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px 0;

    @include desktop-up {
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 15px;
    }
}

.comment__heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 100;
    font-family: $font-family-heading;
}

.required-text {
    position: absolute;
    bottom: 0;
    margin: 0;
    font-size: 12px;
    line-height: 40px;
    font-weight: normal;

    @include desktop-up {
        bottom: 15px;
    }
}

.pink-text {
    color: $vtpink;
}

.sidebar {

    .sidebar {
        background: $yellowgrey;
        padding: 20px;
        width: 100%;
        margin-left: 0;

        @include desktop-up {
            width: 336px;
        }

        h2 {
            font-family: $font-family-sub;
            font-size: 40px;
            text-transform: lowercase;
            hyphens: auto;
            text-align: center;
        }
    }

    .article__block--social-media .article-social-list {
        padding: 0 20px;
    }

    .article__block--social-media {
        width: 100%;

        @include desktop-up {
            width: 336px;
            height: 336px;
        }
    }
}

.form-poll {
    font-size: 14px;
    margin-top: 20px;

    p {
        margin: 0;
    }
}

input.poll-submit {
    width: 100%;
    background-color: $vtyellow;
    margin-top: 30px;
    height: 40px;
    text-transform: uppercase;
}

.poll-widget {
    flex-wrap: wrap;
    font-family: $font-family-sub;
    margin-bottom: 20px;
    color: $darkgrey;
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    width: 100%;
    display: block;
    height: 336px;
    padding: 15px;
    cursor: pointer;
    background: $vtlightblue;

    h3 {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
        border-bottom: 1px solid $white;
    }

    &:hover {
        text-decoration: none;
        color: $darkgrey;
    }
}

// styling for the custom radio buttons

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    display: none;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: $black;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid $black;
    border-radius: 100%;
    background: $white;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $black;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sidebar-div {
    font-family: $font-family-sub;
    background: $vtbluegreen;
    margin-bottom: 20px;
    color: $darkgrey;
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    width: 100%;
    height: 336px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background ease-in-out .2s;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        color: $white;
        text-decoration: none;
    }

    @include desktop-up {
        width: 336px;
        height: 336px;
        padding: 0 15px;
    }

    &--second {
        background: $vtyellow;

        &:hover {
            color: $darkgrey;
        }
    }

    &--search {
        flex-direction: column;
        background: $yellowgrey;

        &:hover {
            opacity: 1;
            color: $darkgrey;
        }
    }
}


input.single-field {
    border: none;
    cursor: text;

    &:focus {
        background: $white;
        border: none;
    }
}

.newsletter-step-1,
.newsletter-step-2 {
    width: 100%;
}

.single-field-form,
.newsletter-field-form {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.single-field-form:after {
    content: '\f002';
    @include fontAwesome;
    font-size: 16px;
    position: absolute;
    right: 0;
    z-index: 10;
}

.single-field-submit {
    display: none;
}

.related-posts_section {
    margin: 0 auto;
    max-width: 1260px;
    padding: 0 18px 30px 18px;

    @include desktop-up {
        padding: 0 20px 30px;
    }
}

.categorie__blocks,
.homepage-blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.overlay__block {
    position: absolute;
    width: 300px;
    height: 100%;
    top: 0;
    left: 0;
    background: $black;
    color: $white;
    transition: background ease-in-out .8s;
    z-index: 10;
}

.home__button-container {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.button-load-more-articles {
    position: relative;
    text-transform: uppercase;
    padding: 8px 21px;
    width: auto;
    font-weight: normal;
    background: $yellowgrey;
    line-height: 18px;
    transition: color 0.3s ease, background 0.3s ease;

    &:hover {
        background: $vtgrey2;
    }

    &:after {
        @include fontAwesome;
        content: '\f0d7';
        margin-left: 10px;
        margin-right: 0;
        font-size: 20px;
    }
}

.homepage__div-text {
    margin: 0;
    padding: 20px 0;
    margin-bottom: 30px;
    font-family: $font-family-body;
    font-size: 18px;
    line-height: 1.5555555556em;

    &--nm {
        padding: 0;
    }

    p {
        padding-top: 0;
        margin-top: 0;
    }

    @include desktop-up {
        column-gap: 30px;
        column-count: 2;
        padding-top: 0;
    }
}

.border {
    border: 1px solid $vtgrey3;

    input#s:focus {
        background: none;
    }

    &.single-field-form {
        border: none;
    }

    &.single-field-form:after {
        top: 8px;
        content: '\f054';
        font-size: 16px;
    }
}


.image-404 {
    margin: 30px 0;
}

.homepage-intro-text {
    h1 {
        text-transform: uppercase;
        font-family: $font-family-heading;
        margin-bottom: 0;
        font-size: 40px;
        line-height: 1.2em;
        font-weight: 100;
        margin-top: 30px;
    }
}

.article__block {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $yellowgrey;
    width: 100%;
    height: 300px;
    position: relative;
    padding: 15px;
    text-align: center;
    margin: 10px 0;

    @include desktop-up {
        margin: 0 20px 20px 0;
    }

    &--newsletter {
        display: flex;
        flex-direction: column;

        label {
            font-size: 12px;
        }

        .button__container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-top: 15px;
            align-items: center;
        }

        h2 {
            font-size: 24px;
            margin: 0 0 10px 0;
        }

        input[type="text"],
        input[type="email"] {
            border: none;
            margin: 5px 0;
        }

        a {
            font-size: 12px;
        }
    }

    &--image {
        padding: 0;
    }

    &--social-media {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;

        li {
            margin: 0 10px;
        }

        .social-icon {
            @include fontAwesomeBrand;
            color: $darkgrey;
            font-size: 40px;

            &:hover {
                color: $vtgrey3;
            }

            &--instagram {
                &:before {
                    content: '\f16d';
                }
            }

            &--email {
                &:before {
                    @include fontAwesome;
                    content: '\f0e0';
                    font-size: 40px;
                }
            }

            &--twitter {
                &:before {
                    content: '\f099';
                }
            }

            &--youtube {
                &:before {
                    content: '\f167';
                }
            }

            &--facebook {
                &:before {
                    content: '\f39e';
                }
            }

            &--pinterest {
                &:before {
                    content: '\f0d2';
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .article-social-list {
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0;
        }

        .article-social-item {
            a {
                text-decoration: none;
            }
        }
    }

    &:hover {
        text-decoration: none;

        .overlay__block {
            opacity: 1;
        }
    }

    @include desktop-up {
        width: 300px;
    }

    &--first {
        background: $vtlightgrey;

        &:before {
            @include desktop-up {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border: 11px solid transparent;
                border-right: none;
                border-left-color: $vtlightgrey;
                position: absolute;
                right: -11px;
                top: 50%;
                margin-top: -11px;
                transition: all 0.3s ease;
            }
        }
    }
}

.heading__margin {
    margin-bottom: 18px;
}

.block__heading {
    font-family: $font-family-sub;
    font-size: 40px;
    text-transform: lowercase;
    hyphens: auto;
}

.block__paragraph {
    position: absolute;
    bottom: 0;
    font-size: 18px;
    text-align: left;
    line-height: 20px;
    height: 70px;
    font-family: $font-family-sub;
    background: $yellowgrey;
    margin: 0;
    width: 100%;
    color: $black;
    padding: 15px 10px;
    z-index: 20;
    overflow: hidden;
}

.article__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay__block {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
}

// Reply block

.comments {
    p {
        margin: 0;
    }
}

.comment__container {
    margin: 0 0 30px 0;
}

.comment__heading {
    font-size: 40px;
    font-weight: 100;
    text-transform: lowercase;
}

.comment__content {
    p {
        padding-left: 35px;
    }
}

.comment__author {
    &:before {
        @include fontAwesome;
        font-weight: 100;
        content: '>';
        margin-right: 20px;
        color: $black;
        font-size: 20px;
    }
}

.tags {
    font-family: $font-family-body;
    background: $yellowgrey;
    font-size: 14px;
    color: $darkgrey;
    position: relative;
    padding: 5px 0;
    margin: 30px 0;
    border-bottom: 0;

    strong {
        left: 0;
        line-height: 30px;
        margin: 0 20px;
        font-size: 14px;
        font-weight: normal;
        text-transform: lowercase;
    }

    a {
        background: $white;
        padding: 7px 20px;
        font-family: $font-family-body;
        color: $darkgrey;
        font-weight: 900;
        height: 30px;
        margin: 0 3px;
        transition: color 0.3s ease, background 0.3s ease;

        &:hover {
            background: $vtgrey2;
            text-decoration: none;
        }
    }
}
