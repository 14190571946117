// Styleguide Base.Media

/* Dependencies (at least):
    none
*/

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

figcaption {

}

/* Fullwidth, custom height */
iframe.c-iframe__full {
    width: 100%;
    height: 800px;
    border: 0;
}

 /* 16:9 */
.c-videoframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

 /* 4:3 alternative */
.c-videoframe--3-4 {
    padding-bottom: 75%;
}

.c-videoframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
