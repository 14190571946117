// Styleguide

/* Import stylesheets */


// import fontAwesome
@import "libs/FontAwesome/scss/fontawesome.scss";
@import "libs/FontAwesome/scss/regular.scss";
@import "libs/FontAwesome/scss/solid.scss";
@import "libs/FontAwesome/scss/brands.scss";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// Import Settings and variables
@import "variables/all";

// Import Mixins and Functions
@import "functions/all";

// Standard html tag attributes and styles
@import "base/all";

// Base website structure, containers and divisions
@import "structure/all";

// Custom project Blocks.
@import "components/all";

// learndash override
@import "learndash/all";

