// Styleguide Variables.Typography

/* Dependencies (at least):
    none
*/

@font-face {
    font-family: 'Playfair Display Rg';
    src: url('../../fonts/playfair/PlayfairDisplay-Regular.eot');
    src: url('../../fonts/playfair/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.woff2') format('woff2'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.woff') format('woff'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.ttf') format('truetype'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display Rg';
    src: url('../../fonts/playfair/PlayfairDisplay-Regular.eot');
    src: url('../../fonts/playfair/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.woff2') format('woff2'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.woff') format('woff'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.ttf') format('truetype'),
    url('../../fonts/playfair/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('../../fonts/proxi/ProximaNova-Light.eot');
    src: url('../../fonts/proxi/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proxi/ProximaNova-Light.woff2') format('woff2'),
    url('../../fonts/proxi/ProximaNova-Light.woff') format('woff'),
    url('../../fonts/proxi/ProximaNova-Light.ttf') format('truetype'),
    url('../../fonts/proxi/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova extraBold';
    src: url('../../fonts/proxi/ProximaNova-Extrabld.eot');
    src: url('../../fonts/proxi/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proxi/ProximaNova-Extrabld.woff2') format('woff2'),
    url('../../fonts/proxi/ProximaNova-Extrabld.woff') format('woff'),
    url('../../fonts/proxi/ProximaNova-Extrabld.ttf') format('truetype'),
    url('../../fonts/proxi/ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Thin';
    src: url('../../fonts/proxi/ProximaNova-Thin.eot');
    src: url('../../fonts/proxi/ProximaNova-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proxi/ProximaNova-Thin.woff2') format('woff2'),
    url('../../fonts/proxi/ProximaNova-Thin.woff') format('woff'),
    url('../../fonts/proxi/ProximaNova-Thin.ttf') format('truetype'),
    url('../../fonts/proxi/ProximaNova-Thin.svg#ProximaNova-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('../../fonts/proxi/ProximaNova-Regular.eot');
    src: url('../../fonts/proxi/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proxi/ProximaNova-Regular.woff2') format('woff2'),
    url('../../fonts/proxi/ProximaNova-Regular.woff') format('woff'),
    url('../../fonts/proxi/ProximaNova-Regular.ttf') format('truetype'),
    url('../../fonts/proxi/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../../fonts/proxi/ProximaNova-Bold.eot');
    src: url('../../fonts/proxi/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proxi/ProximaNova-Bold.woff2') format('woff2'),
    url('../../fonts/proxi/ProximaNova-Bold.woff') format('woff'),
    url('../../fonts/proxi/ProximaNova-Bold.ttf') format('truetype'),
    url('../../fonts/proxi/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Text sizes
    // https://css-tricks.com/snippets/sass/correctly-adding-unit-number/
    // Set this to a number around 16 that displays sharply with your chosen font
    $font-size-base: 16;
    
    // opinionated alternatives that render sharply
    $font-size-smallest: $font-size-base - 3px;
    $font-size-smaller: $font-size-base - 2px;
    $font-size-small: $font-size-base - 1px;
    $font-size-medium: $font-size-base + 0px;
    $font-size-large: $font-size-base + 3px;
    $font-size-larger: $font-size-base + 6px;
    $font-size-largest: $font-size-base + 9px;
    $font-size-huge: $font-size-base + 15px;

// Default line height
    $line-height-base: 1.4;
    $line-height-small: $line-height-base - 0.2;
    $line-height-medium: $line-height-base + 0;
    $line-height-large: $line-height-base + 0.2;

// Font families
// See: http://prospects.mhurrell.co.uk/post/updating-the-helvetica-font-stack/
    $font-family-body: Proxima Nova Rg, Helvetica, Arial, Verdana;
    $font-family-header: Proxima Nova Bold, Helvetica, Arial, Verdana;
    $font-family-heading: Proxima Nova Thin, Helvetica, Arial, Verdana;
    $font-family-sub: Proxima Nova Lt, Helvetica, Arial, Verdana;

    $font-playfair--regular: Playfair Display rg, serif;

    $font-proxiregular--bold: Proxima Nova Bold, Helvetica, Arial, Verdana;
    $font-proxiregular--extrabold: Proxima Nova extraBold, Helvetica, Arial, Verdana;
    $font-proxiregular--light: Proxima Nova Lt;
    $font-proxiregular--regular: Proxima Nova Rg, Helvetica, Arial, Verdana;
    $font-proxiregular--thin: Proxima Nova Thin, Helvetica, Arial, Verdana;

// Font weights (needs to be set per font, cannot be used blindly)
    $font-weight-thin: 100;
    $font-weight-extra-light: 200;
    $font-weight-light: 300;
    $font-weight-regular: 400;
    $font-weight-medium: 500;
    $font-weight-semi-bold: 600;
    $font-weight-bold: 700;
    $font-weight-extra-bold: 800;
    $font-weight-black: 900;
