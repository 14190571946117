.oneRow {
    display: grid;
    margin-bottom: 20px;
    grid-auto-rows: auto;
    gap: 10px;
    grid-template-columns: 20fr 20fr;
    
    @include tablet-up {
        gap: 20px;
        grid-template-columns: 10fr 10fr 10fr 10fr;
    }

    a:hover {
        text-decoration: none;
    }

    &__item {
        height: 100%;
        overflow: hidden;
    }

    &__size {
        &--2-1 {
            grid-column-end: span 2;
        }
    }

    &__image {
        width: 100%;
    }

    &__image-container {
        position: relative;
        min-height: 158px;
    }

    &__label {
        font-size: 10px;
        padding: 0.4em;
        text-transform: uppercase;
        font-family: $font-family-body;
        position: absolute;
        left: 0;
        bottom: 0;
        
        @include tablet-up {
            font-size: 16px;
        }
    }
    
    &__content {
        background: $yellowgrey;
        height: 20%;
        min-height: 70px;
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__title {
        font-family: $font-family-sub;
        margin: unset;
        font-size: 14px;
        color: $black;

        @include tablet-up {
            font-size: 25px;
        }
    }
}