.fullwidth {

    &__content {
        background-color: $yellowgrey;
        padding: 20px 0;
    }

    &__title {
        text-align: center;
        font-family: $font-playfair--regular;
        font-size: 22px;
        margin: 0;
        letter-spacing: 1.6px;
        color: $black;
        font-weight: normal;
        text-transform: uppercase;
        
        @include tablet-up {
            font-size: 32px;
        }
    }

    &__text {
        width: 85%;
        margin: 0 auto;
        text-align: center;
        font-size: 18px !important;
        line-height: 28px;
        color: $black;
        word-break: break-all;
        font-family: $font-family-sub;
        word-break: break-word;
        
        @include tablet-up {
            font-size: 16px !important;
            width: 45%;
            font-weight: lighter;
        }
    }

    &__line {
        margin-bottom: 5px;
        border: 0;
        margin-top: 5px;
        clear: both;
        display: block;
        width: 40%;
        background: none;
        border-bottom: 1px dotted $darkgrey;
        height: 1px;
        @include transition-medium-start;
    }

    &__button {
        display: block;
        text-align: center;
        padding: .4em 1em 0.4em;
        margin: 0 auto 10px;
        font-size: 18px;
        width: fit-content;
        font-family: $font-family-body;
        margin-top: 10px;
        color: $black;
        text-transform: uppercase;
        color: $grey-darkest;
        
        &:hover {
            text-decoration: none;
            color: $grey-darkest;
        }
        
        &:visited {
            text-decoration: none;
            color: $grey-darkest;
        }
    }
}