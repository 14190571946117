// Styleguide Structure.Layout

/* Dependencies (at least):
    Variables.Global
    Variables.Breakpoints
    Functions.Media-queries
    Functions.Mixins
*/

// Dependencies: Variables.Breakpoints, Variables.Globals, Functions.Mediaqueries, Functions.Mixins

// Default page layout: Main column, body with content and sidebar and a sticky footer
//
// Markup
/*
<div class="s-column s-column--{{ banded or vertical }}">
    <header class="s-masthead">
        <div class="s-column__wrapper">
        </div>
    </header>
    <div class="s-body">
        <div class="s-column__wrapper">
            <main class="s-content">
            </main>
            <aside class="s-sidebar">
            </aside>
        </div>
    </div>
    <footer class="s-footer">
        <div class="s-column__wrapper">
        </div>
    </footer>
</div>
*/
// Styleguide Structure.Layout

// Content column settings
.s-column {}

// For sites that have a central column but uses "bands" that stretch from the left to right of the viewport
.s-column--banded {
    width: 100%;

    .s-column__wrapper {
        @include body-container;
        position: relative;
    }
}

.inner-wrapper-sticky {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sidebar {
    margin: 20px;

    @include desktop-up {
        margin: 0 0 0 40px;
    }
}

// For sites with a single background layer and one central column. No horizontal division.
.s-column--vertical {
    @include body-container;
}

// Structure, this decides dimensions and responsive flow
// The masthead usually holds the logo, menu and meta-links.
.s-masthead {
    @include clearfix;
    height: $header-height;
    width: 100%;
}

// The body holds the content, sidebar, breadcrumbs etc.
.s-body {
    @include clearfix;
    min-height: $body-height;

    .s-column__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 1260px;
            padding: 28px 20px 0;
            margin: 0 auto;
    }
}

.s-wrapper {
    @include clearfix;
    @include collapse-margins;
    @include margin-bottom;
    margin-top: $margin-medium;

    @include widescreen-up {
        padding: 0;
    }
}


#scrolltotop {
    text-decoration: none;
    pointer-events: none;
    right: 15px;
    bottom: 15px;
    display: inline-block;
    background-color: $black;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    z-index: 1000;
    visibility: visible;

    @include desktop-up {
        bottom: 30px;
        right: 30px;
    }

    &:hover {
        cursor: pointer;
        background-color: #333;

        @include desktop-up {
            background-color: #333;
        }
    }

    &:active {
        background-color: #555;
    }

    &::after {
        content: fa-content($fa-var-chevron-up);
        font-size: 2em;
        line-height: 50px;
        color: $white;
    }

    &.show {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
    }
}

// The content holds the actual article
.s-content {
    @include clearfix;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include desktop-up {
        margin-left: auto;
        margin-right: auto;
        width: $column-left;
    }

    &.s-content--fullwidth {
        width: 90%;
        margin-top: 40px;
        padding: unset;

        @include tablet-up {
            padding: 28px 30px 0;
        }
        @include desktop-up {
            margin-top: auto;
            width: 100%;
            padding: 28px 20px 0;
        }
    }
}

.homepage-content {
    max-width: 1260px;
    padding: 0 20px;
    margin: 0 auto;
}

// The sidebar holds widgets and meta-data relevant to the content.
.s-sidebar {
    @include clearfix;

    @include desktop-up {
        float: right;
        width: $column-right;
    }
}

// The footer ends the site and holds menu's and other links to give the reader a destination.
.s-footer {
    @include collapse-margins;
    min-height: 400px;

    @include desktop-up {
        min-height: $footer-height;
    }
}

.seperator {
    height: 1px;
    background: $vtgrey2;
    margin: 20px 0;
    display: block;
}

input[type="search"] {
    border-radius: 0;
    -webkit-appearance: none;
}
