.learndash-footer {
    position: relative;

    &__wrapper {
        position: absolute;
        z-index: 99;
        top: 100%;
    }
    
    .footer-static__wrapper {
        background-color: $white;
        position: relative;
        width: 99vw;
    }
}

.learndash-wrapper .ld-focus {
    position: relative;
    min-height: calc(100vh - 55px);
    padding-bottom: 55px;
}
