.footer-social {
	padding-top: unset!important;
}

.footer-social {
	div	h2 {
		font-size: 25px;
		color: $black;
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 2px;
		position: relative;
	}
}
.s-footer {
	background: $yellowgrey;
	padding: 32px 40px;

	&:after {
		margin-top: unset !important;
	}

	.s-footer__wrapper {
		padding: 10px 10px 20px;
		display: flex;
		flex-direction: column-reverse;

		@include desktop-up {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0 auto;
			max-width: 1260px;
			padding: 28px 20px;
			.s-column__wrapper {
				&:first-child {
					max-width: 40%;
					display: block;
				}

				&:last-of-type {
					margin-bottom: 40px;
				}
				width: 100%;
			}
			.s-column__wrapper div {
				h2 {
					font-family: $font-family-sub;
					font-size: $font-size-medium;
					text-transform: lowercase;
				}
				h2:before {
					display: none;
				}
				.menu {
					display: block;
					li {
						font-size: $font-size-small;
						text-decoration: none;

						& > a:hover {
							color: $darkgrey;
							text-decoration: underline;
						}
					}

				}
			}
		}
	}
}

.checkbox-container {
	margin-top: 12px;

	label {
		span {
			display: block !important;
		}
	}
}

.static-footer {
	background: $white;
	.static-footer__waarborg {
		font-size: 10px;
		line-height: 8px;
	}
}

.static-footer__column {
	ul {
		width: 100%;
		text-align: center;
		padding: 0;
	}
	ul li {
		color: $darkgrey;
		font-size: 10px;
		list-style: none;
		margin: 4px 0;
		a {
			color: $darkgrey;
			font-size: 12px;
		}
	}

	.thuiswinkel-waarborg {
		font-size: 9px;
		text-align: left;
		width: 50px;
		display: block;
		margin: 14px auto 20px;
		padding-left: 10px;
		line-height: 8px;
		position: relative;
		&:before {
			content: '';
			background-image: url('/wp-content/themes/magazines-base/assets/img/src/thuiswinkel-waarborg-small.png');
			background-size: cover;
			height: 16px;
			width: 16px;
			display: block;
			position: absolute;
			left: -10px;
			bottom: 1px;
		}
		@include desktop-up {
			display: inline-block;
			margin-left: 30px;
			line-height: 0;
			&:before {
				width: 22px;
				height: 22px;
				left: -16px;
				bottom: -5px;
			}
			strong {
				position: absolute;
				bottom: 10px;
			}
		}
	}

	@include desktop-up {
		ul {
			width: auto;
			margin-right: 20px;
			font-size: 16px;
			li {
				margin: 0 10px;
				display: inline-block;
			}
		}
	}
}

.footer__static-images {
	padding: 10px 0 20px 0;
	ul {
		list-style: none;
		padding: 0;
		text-align: center;
		padding-left: 12px;
		li {
			display: inline-block;
			max-height: 15px;
			img {
				max-height: 32px;
				height: 25px;
			}
		}
		li.afterpay {
			margin-left: -10px;
			img {
				height: 16px;
			}
		}
	}
	@include desktop-up {
		display: flex;
		flex-direction: row-reverse;
		max-width: 1260px;
		margin: 0 auto;
		ul {
			display: inline-flex;
			justify-content: flex-end;
		}
	}
}

.copyright {
	font-size: 12px;
	text-align: center;
	padding: 2px 20px;
	margin-bottom: 10px;

	&--learndash {
		margin: 0 auto;
	}

	p {
		margin-bottom: 0;
		margin-top: 8px;
	}
	@include desktop-up {
		width: auto;
		padding: 2px 0 2px 10px;
	}
}

footer .s-column__wrapper {

	.widget {
		height: auto;
	}
	padding-left: 16px;
	&:first-child {

		@include desktop-up {
			padding-left: 0;
		}
	}
	div h2 {
		color: $black;
		font-weight: bold;
		font-size: $font-size-smaller;
		font-family: $font-family-sub;
		margin-bottom: 2px;
		position: relative;
		&:before {
			font-family: 'Font Awesome\ 5 Free';
			content: '\f053';
			position: absolute;
			left: -20px;
			@include transition-fast-end;
			transform: rotate(-180deg);
		}
		&.active {
			&:before {
				transform: rotate(-90deg);
			}
		}
	}
	.widget {
		position: relative;
		display: flex;
		flex-direction: column;

		@include desktop-up {
			margin-bottom: 0;
		}
		
		h3 {
			position: absolute;
			font-size: 16px;
			color: $black;
			font-weight: bold;
			margin-top: 14px;
		}
		input[type='email'], input[type='text'] {
			height: 24px;
			padding: 0;
			font-size: 12px;
			line-height: 24px;
			background: none;
			box-shadow: none;
			border: 0;
			border-bottom: 1px solid #525252;
			position: relative;
			transform: translateX(0%);
			@include transition-fast-end;
			margin-top: 63px;

			@include desktop-up {
				margin-top: 46px;
			}
		}
		input[type='text'] {
			margin-top: 10px;
		}

		.submit-button-newsletter-subscription {
			position: absolute;
			top: 57px;
			right: 0;
			height: 24px;
			padding: 6px 12px;
			margin-top: 5px;
			font-size: 12px;
			transform: scaleX(-1), rotate(0deg);

			@include desktop-up {
				margin-top: -12px;
			}
		}
	}
	.menu {
		list-style: none;
		margin-top: 6px;
		padding: 0;
		display: none;
		li {
			color: $darkgrey;
			font-size: $font-size-smallest;
			a {
				display: inline-block;
				color: $darkgrey;
				padding: 2px 0;
				transition: color 0.3s ease, background 0.3s ease;
				text-transform: lowercase;
				line-height: 30px;

				&:hover {
					color: $vtpink;
					text-decoration: none;
				}
			}
		}
	}
}

.footer-social {

	>h2:before {
		content: '' !important;
	}

	.widget-social-list {
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: flex-start;

		li {
			list-style: none;
		}
	}

	.social-item {
		width: 35px;
		height: 35px;
		    
		img {
			width: 100%;
			height: auto;
		}
	}
	.footer-col-title {
		color: $black;
		font-weight: bold;
		font-size: $font-size-smaller;
		margin-bottom: 0;
	}

	ul.list {
		list-style: none;
		padding: 0;
		margin: 2px 0;
		li {
			display: inline-block;
			margin-bottom: 0;
		}
		.footer-social-link {
			display: block;
			height: 35px;
			width: 35px;
			background: url(/wp-content/themes/magazines-base/assets/img/src/social-icons-large@2x.png?v=1.2) no-repeat;
			background-size: 210px 70px;
		}
		.img-replace {
			text-indent: -9999em;
			white-space: nowrap;
			overflow: hidden;
			display: block;
		}
		.footer-social-item-mail {
			.footer-social-link {
				background-position: -105px 0;
			}
		}
		.footer-social-item-instagram {
			.footer-social-link {
				background-position: -140px 0;
			}
		}
		.footer-social-item-facebook {
			.footer-social-link {
				background-position: 0 0;
			}
		}
		.footer-social-item-pinterest {
			.footer-social-link {
				background-position: -70px 0;
			}
		}
		.footer-social-item-twitter {
			.footer-social-link {
				background-position: -35px 0;
			}
		}
		.footer-social-item-youtube {
			.footer-social-link {
				background-position: -175px 0;
			}
		}
	}
}

.static-footer {
	@include desktop-up {
		padding: 0;
	.footer-static__wrapper {
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-between;
			.copyright, .static-footer__column {
				display: inline-flex;
				justify-content: center;
			}

			@include desktop-up {
				max-width: 1260px;
				margin: 0 auto;
				padding: 0;
			}
		}
	}
}
