.breadcrumb-section {
	display: none;

		@include desktop-up {
		display: block;
		max-width: 1260px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		padding: 0 10px;
	}
}

.breadcrumb {
	display: flex;
}

.breadcrumb__item {
	a {
	text-transform: lowercase;
	color: $darkgrey;
	padding: 0 10px;
	font-family: $font-family-body;
	font-size: 16px;
}

	&--category {
		color: $darkgrey;

		a {
			padding: 0 10px;
			color: $darkgrey;

			&:hover {
				text-decoration: none;
				color: $vtpink;
			}
		}
	}

	&--active {
		padding: 0 10px;

		a {

			&:hover {
				color: $vtpink;
			}
		}
	}
}