.homepage {

    &__title {
        letter-spacing: 1.6px;
        color: $black;
        font-weight: normal;
        text-transform: uppercase;
    }
    
    & > p {
        font-size: 18px;
        line-height: 28px;
        color: $black;
        font-family: $font-family-sub;
        
        @include desktop-up {
            font-weight: lighter;
            width: calc(100% - 356px);
        }
    }
}
.home div.s-column__wrapper {
    padding: unset;

}
