.search {
    .heading__margin {
        display: inline-block;
        font-size: 18px;
        font-family: $font-family-sub;
        font-weight: 300;

        @include desktop-up {
            font-size: 28px;
            line-height: 32px;
            margin-top: 45px;
            margin-bottom: 0;
        }
    }

    .homepage-intro-text {
        h1 {
            display: inline-block;
            font-size: 18px;
            font-family: $font-family-sub;
            font-weight: bold;
            text-transform: uppercase;

            @include desktop-up {
                font-family: $font-playfair--regular;
                margin-top: 0;
                margin-bottom: 40px;
                color: $darkgrey;
            }
        }
    }
}
