// Styleguide Base.Forms

/* Dependencies (at least):
    Variables.Global
    Variables.Typography
*/

.c-form__row {
    @include margin-bottom;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

legend {
    @include margin-bottom;
    display: table;
    white-space:normal;
}

label,
.label {
    margin-bottom: 0;
}

input,
button,
select,
textarea {
    border: none;
    border-radius: 0;
    border-width: 0;
    outline: none;
}

input[type="submit"], button, input[type="button"] {
   appearance: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="file"],
select,
textarea {
    background: color(input-background);
    border: 1px solid color(input-border);
    font-family: $font-family-body;
    font-size: $font-size-medium;
    line-height: $button-small;
    min-width: 1px;
    padding: $margin-smaller $margin-smaller;
    width: 100%;

    &:focus {
        background: color(input-background-focus);
        border: 1px solid color(input-border-focus);
    }
}

input:disabled {
    //OPTIONAL background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQIW2NkQAKrVq36zwjjgzhhYWGMYAEYB8RmROaABADeOQ8CXl/xfgAAAABJRU5ErkJggg==) repeat;
}
.button,
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="checkbox"],
input[type="radio"] {
    background: color(button-background);
    color: color(button-text);
    cursor: pointer;
    font-size: $font-size-medium;
    width: auto;
    padding: .4em 1em .5em;

    &:hover {
        background: color(button-background-hover);
    }
}

textarea {
    line-height: 1.5;
    min-height: 7em;
    resize: vertical;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // background-image: url();
    background-position: right -3px center;
    background-repeat: no-repeat;
}

/* http://stackoverflow.com/questions/9707021/how-do-i-auto-hide-placeholder-text-upon-focus-using-css-or-jquery */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: color(form-placeholder);
}
::-moz-placeholder { /* Firefox 19+ */
    color: color(form-placeholder);
    opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
    color: color(form-placeholder);
}
:-moz-placeholder { /* Firefox 18- */
    color: color(form-placeholder);
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

a.readmore {
    color: $darkgrey;
}

.readmore {
    background: $vtyellow;
    font-size: 14px;
    padding: 0 20px;
    text-transform: uppercase;
    display: inline-flex;
    padding: 9px 20px;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease;

    &:after {
        @include fontAwesome;
        content: '\f0dd';
        color: $darkgrey;
        margin-left: 20px;
        margin-right: 0;
        transition: 0.3s;
    }

    &:hover {
        opacity: 0.6;
        color: $darkgrey;
        text-decoration: none;

        &:after {
            margin-left: 30px;
        }
    }
}

.checkbox-container {
    display: flex;

    label {
        display: flex;
        align-items: center;
        span {
            margin-left: 12px;
            display: inherit;
            margin-top: 0;
            font-size: 12px;
        }
    }

    label input[type="checkbox"] {
        position: relative;

        &:before {
            background: $white;
            border: 1px solid $vtgrey;
            height: 21px;
            content: " ";
            display: inline-block;
            width: 21px;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: -1px;
        }
    }

    label input[type="checkbox"]:checked {
        &:before {
            @include fontAwesome;
            content: '\f00c';
            color: $darkgrey;
            font-size: 16px;
            text-align: center;
            line-height: 21px;
        }
    }
}

// gravity form style
.gform_wrapper .top_label .gfield.hide-label > label.gfield_label {
    display: none;
}

.gform_wrapper .top_label .gfield_checkbox li > label {
    padding-left: 14px;
}

.gform_wrapper .top_label .gfield_checkbox li {
    input[type="checkbox"] {
        &:before {
            background: $white;
            border: 1px solid $vtgrey;
            height: 21px;
            content: " ";
            display: inline-block;
            width: 21px;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: -1px;
        }
    }

    input[type="checkbox"]:checked {
        &:before {
            @include fontAwesome;
            content: '\f00c';
            color: $darkgrey;
            font-size: 16px;
            text-align: center;
            line-height: 21px;
        }
    }
}

.gform_wrapper .gfield_checkbox li input[type=checkbox],
.gform_wrapper .gfield_radio li input[type=radio] {
    position: relative;
    vertical-align: top;
}

.gform_wrapper .gfield_checkbox li, .gform_wrapper .gfield_radio li {
    margin-bottom: 20px;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield input[type=radio] {
    display: none;
}

.gform_wrapper .top_label li ul.gfield_radio {
    margin-top: 20px;
}

.gform_wrapper .gform_footer input[type=submit] {
    background: $white;
    text-transform: uppercase;
    padding: 10px 24px;
    transition: color 0.3s ease, background 0.3s ease;

    &:hover {
        background: $darkgrey;
        color: $white;
    }
}

.gform_wrapper ul.gfield_radio li,
.gform_wrapper ul.gfield_checkbox li {
    overflow: auto;
    display: flex;
} 

.gform_wrapper ul.gfield_radio li input[type=radio] {
    display: none;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    width: 100%;
    border: none;
    padding: 4px 5px;

    &:focus {
        border: none;
        background: $white;
    }
}

.gform_wrapper .gfield_checkbox li label, 
.gform_wrapper .gfield_radio li label {
    padding-left: 32px;
}


