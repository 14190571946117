// Styleguide Components.Masthead

header.s-masthead {
  background-color: $grey;
  padding: 12px;
  padding-bottom: 20px;
  position: relative;
  height: auto;
  @include desktop-up {
    padding-bottom: 0;
  }
  .s-column__wrapper {
    @include desktop-up {
      margin: 0 auto;
      display: block;
      max-width: 1260px;
      padding: 28px 20px 0;
    }
  }
  .top-right-header-menu {
    position: absolute;
    right: 22px;
    top: 18px;
    @include desktop-up {
      position: relative;
      float: right;
      top: 0;
      right: 0;

      & > a {
        height: 100%;
          & > img {
          height: 50px;
          }
      } 
        
    }
    .fa-heart {
      font-size: $font-size-large;
    }
  }

  .search {
    @include desktop-up {
      display: inline-block;
      text-align: center;
      width: 70%;
      padding: 0 60px;
      margin-bottom: 15px;
    }
    form {
      display: inline-block;
      position: relative;
      width: 100%;
      @include desktop-up {
        max-width: 581px;
        margin: 0 auto;
        width: 100%;
      }
    }

    .search-button {
      position: absolute;
      right: 0;
      top: 0;
      width: 44px;
      height: 44px;
      background-color: transparent;
      color: $black;
      font-size: $font-size-large;
      padding: 0;
      @include transition-medium-end;

      &:before {
        @include transition-medium-end;
      }
    }

    form input[type="search"] {
      padding: 10px 50px 10px 14px;
      font-size: 14px;
      line-height: 24px;
      background-color: $white;
      border: none;

      &:valid + .search-button {
        background: $green;
        color: $white;
        @include transition-medium-end;
        width: 88px;

        &:hover {
          color: $black;
        }

        &:before {
          @include transition-medium-end;
        }
      }
    }
  }
}