.learndash .learndash-wrapper {
    .learndash_mark_complete_button,
    div.ld-course-status {
        border-radius: unset;
    }

    span.ld-status {
        border-radius: unset;
        padding: 5px 20px;
    }

    // this item could not be refrenced by element.class name so had to use !important
    .btn-join {
        color:$black !important;
        border-radius: unset !important;
    }

    div.ld-expand-button,
    a.ld-button {
        border-radius: unset;
        text-transform: uppercase;
        color: $black;
    }

    .learndash-wrapper .ld-expand-button .ld-button-alternate .ld-text,
    .ld-icon,
    .ld-primary-color {
        text-transform: initial;
        color: $black !important;
    }

    .payment-button {
        background: color(button-background);
        color: color(button-text);
        cursor: pointer;
        font-size: $font-size-medium;
        width: auto;
        padding: .4em 1em .5em;

        &--block {
            background: color(block-button-background);
        }

        &:hover {
            background: color(button-background-hover);
        }
    }
}

a.learndash {

    &__register {
        text-decoration: underline;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
    }
}

div.learndash_checkout_buttons {
    margin-right: 5px !important;
}
