#voucher-form {

    label {
        @extend h4; 
        font-weight: 700;
    }

    input {
        margin-top: .2em;
        width: calc(100% - 165px);
    }

    button {
        width: 150px;
        height: 40px;
        margin-left: 10px;
    }

    small {
        display: block;
        margin-top: .5em;
    }

    @include phone-down {
        text-align: center; 

        input {
            margin-top: .5em;
            width: 100%; 
        }

        button {
            margin-top: 1em;
            width: 100%;
            margin-left: unset;
            margin-bottom: .5em;
        }

        .status {

        }
    }

    .status {
        padding: .3em .6em;
        margin-top: .8em;
        text-align: center; 

        &.success {
            color: $green;
            border: 1px solid $green;
            background: rgba($green, 0.05);
        }

        &.error {
            color: $red;
            border: 1px solid $red;
            background: rgba($red, 0.05);
        }
    }

}