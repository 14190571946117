.notfound {
    @include desktop-up {
        &::before {
            content: '';
            background-color: $darkgrey;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include transition-medium-start;
            z-index: 19 !important;
        }
    }
    margin-top: 40px;
    padding: 0 20px;
    width: 100%;
    background-color: #f5f5f2;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 300px;
        width: 100%;
    }

    &__content {
        padding: 25px;
        display: flex;
        align-content: space-around;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: auto;
        position: relative;
        z-index: 23;
    }
    &__title {
            font-family: $font-playfair--regular;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0.88px;
            color: #000000;
            text-transform: uppercase;
            margin: 15px 80px 14px;
            line-height: 22px;
            @include transition-medium-start;
    }

    &__line {
        margin-bottom: 0;
        border: 0;
        clear: both;
        display: block;
        width: 40%;
        background: none;
        border-bottom: 1px dotted $darkgrey;
        height: 1px;
        @include transition-medium-start;
    }

    &__subtitle {
        padding: 10px 10px 14px;
        font-family: $font-proxiregular--light;
        font-weight: 300;
        letter-spacing: normal;
        color: #000000;
        font-size: 18px;
        line-height: 24px;
        width: 85%;
        @include transition-medium-start;
    }

    &__button {
        font-family: $font-proxiregular--regular;
        width: auto;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-decoration: none;
        background: color(button-background);
        color: $black;
        cursor: pointer;
        padding: 11px 10px;
        margin-bottom: 20px;

        @include widescreen-up {
            font-size: 17px;
        }
    }

    // Desktop
    @include desktop-up {
        position: relative;
        margin-top: 12px;
        padding: 0;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            max-height: 575px;
        }

        &__content {
            position: absolute;
            width: 25%;
            min-width: 400px;

            @include widescreen-up {
                width: 25%;
                min-width: 300px;
            }

            z-index: 20;
            background-color: #ffffff;
            left: 40px;
            bottom: 40px;
            padding: 40px;
            opacity: 1;
        }

        &__title {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: 1.6px;
            margin: auto;
            @include desktop-up {
                font-size: 32px;
                line-height: 36px;
                letter-spacing: 1.6px;
            }

            @include widescreen-up {
                font-size: 36px;
            }
        }

        &__line {
            width: 60%;
            margin: 14px 0 15px;
        }

        &__subtitle {
            padding-bottom: 40px;
        }

        &__button {
            font-family: $font-proxiregular--regular;
            font-size: 18px;
            line-height: 22px;
            padding: 6px 12px 4px;
            opacity: 1;

            &:hover {
                @include transition-medium-start;
                background: $vtyellow;
                text-decoration: none;
                opacity: 0.65;
            }
        }
    }

    &:hover {
        .notfound__title, .notfound__line, .notfound__subtitle {
            @include transition-medium-start;
            opacity: 0.65;
        }
    }
}
