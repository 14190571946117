#ld-profile {

    .ld-profile-heading {
        font-family: $font-family-sub;
    }

    .ld-profile-stats {
        text-transform: lowercase;
        font-family: $font-family-body;
    }

    .ld-item-list-actions > .ld-expand-button {
        border-radius: 0;
        text-transform: uppercase;
    }
}