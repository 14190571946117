// Styleguide Components.Menu
.header-menu {
    max-width: 100%;
    overflow: hidden;
    max-height: 170px;

    &:hover {
        overflow: initial;
    }
    .nav-main {
        display: none;
        @include desktop-up {
            display: block;
            padding-left: 0;
            margin: 22px 0 12px;
        }
    }

    .nav-profile-image {
        width: 65px;
        display: inline-block;
        padding: 0 1em;
    }

    .nav-login-item {
        color: black;
        font-size: 16px;
        padding: 10px 0 0;
        font-family: $font-family-header;
        margin: 0 auto;
        display: inline;

        @include desktop-up {
            margin: unset;
        }
        
        & > button {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .nav-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .nav-mobile-holder {
        transform: translateX(-100%);
        position: fixed;
        @include transition-fast-end;
        background: $grey;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        display: none;

        &.active {
            transform: translateX(0%);
            z-index: 1231;
            overflow: auto;
            display: block;
        }
        .nav-mobile {
            background: $white;
            text-transform: lowercase;
            height: 100%;
            padding: 8px 16px;
        }

        .nav-main-link {
            color: $darkgrey;

            &:hover {
                text-decoration: none;
            }
        }

        ul li {
            padding: 10px 0;
            border-bottom: $grey 1px solid;
            margin: 0;
            width: 100%;
            list-style: none;

            a {
                display: block;
                font-weight: 400;
                color: $darkgrey;
                text-transform: lowercase;
            }
        }
        li.menu-item-has-children {
            position: relative;
            i {
                position: absolute;
                right: 0;
                top: 14px;
            }
        }
        ul.nav-drop {
            position: fixed;
            z-index: 1232;
            background: white;
            height: 100%;
            width: 100%;
            top: 57px;
            left: 0;
            @include transition-fast-end;
            transform: translateX(100%);

            @include desktop-up {
                position: relative;
            }

            li {
                text-transform: none;
                font-size: $font-size-medium;
                padding: 6px 0;
                &:first-child {
                    border-top: solid 1px $grey;
                }
                a {
                    font-size: $font-size-small;
                    text-transform: none;
                }
            }
            &.active {
                transform: translateX(0%);
            }
            .back-btn {
                background: transparent;
                color: grey;
                position: relative;
                padding-left: 26px;
                margin-left: -8px;
                i {
                    position: absolute;
                    transform: rotate(180deg);
                    left: 0;
                    top: 8px;
                    width: 20px;
                }
            }
            .parent-menu {
                color: $black;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                font-size: $font-size-base;
                margin: 10px 0 4px -10px;
            }
        }
        .close-holder {
            position: relative;
            width: 100%;
            top: 0;
            height: 48px;
        }
        .close-mobile {
            position: absolute;
            background: transparent;
            right: 2px;
            top: 8px;
            &:hover {
                cursor: pointer;
            }
            &:after {
                content: 'sluiten';
                font-size:  $font-size-smallest;
                top: 20px;
                padding-top: 20px;
                display: inline-block;
            }
            i {
                font-size: $font-size-larger;
                position: absolute;
                left: 0;
                right: 0;
            }
        }
    }

    //desktop menu
    ul.nav-main {
        position: relative;
        width: fit-content;
        height: 58px;
        margin: 0;
        padding: 14px 0 0;
        li {
            list-style: none;
            display: inline;
            margin: 0;
            overflow: hidden;

            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            > a {
                -webkit-font-smoothing: antialiased;
                font-weight: 300;
                box-sizing: border-box;
                font-size: 16px;
                color: $black;
                text-transform: lowercase;
                -webkit-font-feature-settings: normal;
                padding: 6px 16px 22px;
                @include transition-fast-end;
                height: 50px;
                font-family: $font-proxiregular--regular;
                transition: all 200ms ease-in;

                &:hover {
                    color: $darkgrey;
                }
            }

            &.current-menu-item {
                a {
                    font-weight: 300;
                    font-family: $font-family-header;
                    &:after {
                        display: block;
                    }
                }
            }
            .nav-drop {
                position: absolute;
                z-index: 1232;
                background: white;
                min-height: 200px;
                width: 100%;
                left: 0;
                top: 57px;
                padding: 20px;
                > li {
                    overflow: initial;
                    height: initial;
                    box-sizing: border-box;
                    margin: 0 20px 0 0;
                    a {
                        font-size: 15px;
                        text-transform: none;
                    }
                }
                > li > a {
                    font-weight: $font-weight-bold;
                    text-transform: lowercase;
                    font-family: $font-family-header;
                    padding: 0;
                    margin-bottom: 9px;
                    height: 26px;
                    display: inline-block;
                    opacity: 1;
                }
                .nav-drop--third {
                    display: block;
                    margin: 0 0 20px;
                    padding: 0;
                    text-align: left;
                    color: $black;
                    font-weight: $font-weight-light;
                    li, a {
                        font-family: $font-family-body;
                        height: auto;
                        margin: 0;
                    }
                    a {
                        padding: 4px 0;
                        opacity: 1;
                    }
                    > li {
                        height: 30px;
                        display: block;
                    }
                }

            }
            &.menu-item-has-children {

                >a {
                    position: relative;

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 12px 12px 12px;
                        border-color: transparent transparent $white transparent;
                        position: absolute;
                        top: 35px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transform: translateY(12px);
                        @include transition-fast-end;
                    }
                }

                & > ul {
                    display: none;
                }

                &:hover {
                    a:after {
                        opacity: 1;
                    }

                    &:after {
                        content: '';
                        background-color: black;
                        pointer-events: none;
                        opacity: 0.7;
                        width: 200vw;
                        height: 100vh;

                        position: absolute;
                        top: 57px;
                        left: -50%;
                        z-index: 100;
                    }

                    & > ul {
                        display: block;
                    }

                    a:before {
                        transform: translateY(1px);
                    }

                    .children-arrow {
                        &:before {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                a {
                    opacity: 0.8;
                    text-decoration: none;
                }
            }
        }
    }
}

// mobile menu
.mobile-button {
    display: block;
    position: absolute;
    color: $darkgrey;
    width: 40px;
    height: 40px;
    top: 16px;
    left: 14px;
    font-size: $font-size-smallest;
    background: transparent;
    padding-top: 20px;
    @include desktop-up {
        display: none;
    }

    span {
        display: block;
        width: 22px;
        height: 3px;
        background: $darkgrey;
        position: absolute;
        left: 6px;
        top: 0;
        &:nth-child(2) {
            top: 7px;
        }
        &:nth-child(3) {
            top: 14px;
        }
    }
    .mobile-button__desc {
        position: absolute;
        left: 0;
        bottom: 4px;
    }
}

// mobile body state
body.mobile-active {
    overflow: hidden;
}
