// Styleguide Components.Logo

.c-logo {
    // background: url(images/logo.png) left center / contain no-repeat;
}

.logo {
	display: block;
	margin: 0 auto;
	width: 136px;
	img {
		max-width: 136px;
		padding-bottom: 14px;
	}
	@include desktop-up {
		margin: auto auto 30px;
		display: inline-block;
		position: relative;
		height: 26px;
		img {
			top: 5px;
			max-width: 162px;
			position: absolute;
		}
	}

	&__wrapper {
		@include desktop-up {
			display: inline-block;
		}
	}
}
