.slick-dots {
    position: absolute;
    list-style: none;
    right: 10px;
    bottom: 0;
    display: flex!important;

    @include desktop-up {
        right: 60px;
    }
}

.slick-dots > li > button {
   width: 20px;
   height: 20px;
   padding: 0;
   border-radius: 50%;
   font-size: 11px;
    background: $white;

    &:first-of-type {
        margin-right: 8px;
    }
}

.slick-active > button{
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 11px;
    background: $darkgrey!important;
    color: $white;
}