// Styleguide Components.Pages.Articles

.c-content {
}

#m-r1 {
    display: block;
    margin: 20px 0;
    padding: 20px 0;
    background: $grey;

    @include tablet-up {
        display: none;
    }
    
    iframe {
        display: block;
        margin: 0 auto;
    }
}

#m-r2 {
    display: flex;
    justify-content: center;
    margin: 12px auto 0;
    padding: 20px;

    @include tablet-up {
        display: none;
    }
}

.sm-ads.sm-rectangle.ads-center {
    margin: 20px 0;
}

.s-sidebar {

    @include desktop-up {
        margin: 0 0 0 40px;
    }

    .widget {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        margin-top: 10px;
        background: $yellowgrey;
        padding: 15px;
        margin-bottom: 20px;
        height: 360px;

        @include desktop-up {
            width: 336px;
            height: auto;
        }
        input[type='checkbox']:checked+label:before {
            background-position: right top;
        }

        & > input[type="text"],
            input[type="email"] {
            border: none;
            margin: 4px 0;
        }

       & > .checkbox-container {
           font-size: 12px;
           margin-top: 10px;
       }

       & > button {
            position: absolute;
            right: 15px;
            top: 127px;
            height: 38px;
       }

       & > h3 {
           text-align: center;
           font-size: 40px;
           margin: 10px 0;
           line-height: 44px;
           font-family: $font-family-sub;
       }
    }
}