// overruled styling of sa account which was breaking
#sa_gender_radio_f, #sa_gender_radio_m {
    position: relative;

    & + label:after {
        transition: all 0.2s ease;
        content: '';
        background: none;
        width: 0;
        height: 0;
    }

    &:checked {
        &::after {
            content: '';
            width: 13px;
            height: 13px;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 100%;
            transition: all 0.2s ease;
        }
    }

}