.storelocator {

  #storelocator-map {
    height: 400px;
    width: 800px;
    float: left;
  }

  #stores {
    height:400px;
    width: 200px;
    float: left;
    overflow-y: scroll;

    .store {
      width: 100%;
      border-bottom: 1px solid gray;

      a {
        display: inline-block;
        width: 100%;
      }

      .marker-link {
        color: black;
        text-decoration: none;
      }

      .store-url {
        margin-top: 0;
      }
    }
  }
}